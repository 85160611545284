<template>
  <!-- 列表展示组件 ⭐⭐修改需参考services/list.js和vuetify⭐⭐ -->
  <van-row class="searchTrain">
    <!--头部日历-->
    <van-sticky>
      <div class="head-background">
        <SliderDate @changeDate="calendarConfirm" :cur="query.depDate" @open="show_calendar = true"
          :start="startDate" />
      </div>
    </van-sticky>

    <!-- 插槽 -->
    <slot name="header"></slot>

    <!-- 列表 -->
    <van-skeleton :row-width="skeletonArr" :row="4 * 7" :loading="listLoad && !(0 in showList)">
      <BaseCol v-if="showChart&&chartData" @chartClick="filterDate" :data="chartData" ref="chart" />
      <div class="gap"></div>
      <div :style="`height: ${listHeight};overflow:hidden`">
        <van-empty v-show="!(0 in showList)" description="暂无航班信息，请尝试更换机场。" />
        <TicketList :list="showList" :listLoad="listLoad" @childClick="chooseTicket($event)" v-show="0 in showList" />
      </div>
      <span class="list-text loading-text" v-show="listLoad">加载中...</span>
    </van-skeleton>

    <div class="gap"></div>

    <!--筛选bar-->
    <van-tabbar style="border: 1px solid #eee" v-model="activenav" safe-area-inset-bottom>
      <van-tabbar-item name="early" class="navicon" icon-prefix="icon" icon="naozhong" replace @click="sortList()">提早
      </van-tabbar-item>
      <van-tabbar-item name="money" class="navicon" icon-prefix="icon" icon="feiyongbaohan" replace @click="sortList()">
        省钱</van-tabbar-item>
      <van-tabbar-item name="time" class="navicon" icon-prefix="icon" icon="shanguangdengdakai" replace
        @click="sortList()">省时</van-tabbar-item>
      <van-tabbar-item name="filter" class="navicon" icon-prefix="icon" icon="shaixuan" replace
        :dot="0 in ticket_filter_activeIds" @click="ticket_filter_show = true">筛选</van-tabbar-item>
    </van-tabbar>

    <!--日期选择-->
    <van-calendar v-model="show_calendar" :show-confirm="false" confirm-disabled-text="请选择出发时间"
      :default-date="new Date(query.depDate)" :minDate="startDate ? new Date(startDate) : new Date()" position="right"
      :round="false" @confirm="calendarConfirm" />

    <!--筛选选项卡-->
    <van-action-sheet v-model="ticket_filter_show" title="筛选" safe-area-inset-bottom :close-on-click-overlay="false">
      <div class="tab_bar_filter_content">
        <van-tree-select :items="ticket_filter_items" :active-id.sync="ticket_filter_activeIds"
          :main-active-index.sync="ticket_filter_activeIndex" />
      </div>
      <van-row type="flex" justify="space-around" align="center" style="margin-bottom: 10px">
        <van-button style="width: 100px" plain type="info" @click="ticket_filter_activeIds = []">清空</van-button>
        <van-button style="width: 100px" type="info" @click="tabbar_filter_confirm">确认</van-button>
      </van-row>
    </van-action-sheet>
  </van-row>
</template>

<script>
import { init } from "@/services/list";
import TicketList from "./TicketList";
import moment from "moment";
import BaseCol from "@/components/chart/BaseCol";
import SliderDate from "@/components/SliderDate";
import LoadingCircle from "@/components/LoadingCircle";
import {
  AircraftServiceProvider,
  AircraftDefaultSort,
  AircraftAsyncList
} from "@/common/config";
import { SortForChart } from "@/services/aircraft";
import SkeletonArr from "@/common/Skeleton.js";

export default {
  name: "SearchTrain",
  props: {
    query: Object,
    secondKey: {
      default: false // 往返的返程给true
    },
    isNormal: {
      default: true // 改签给false
    },
    startDate: {
      default: () => new Date()
    },
    routename: {
      default: "searchAircraftSingle"
    }
  },
  components: { TicketList, SliderDate, BaseCol, LoadingCircle },
  computed: {
    // 筛选项目，当前仅有供应商
    ticket_filter_items: function() {
      let res = [
        {
          text: "服务商",
          children: AircraftServiceProvider.map(item => {
            return {
              text: item,
              id: item
            };
          })
        }
      ];
      return res;
    },

    // 已选供应商【2021.3.11】 虽然跟ticket_filter_items里面东西一样但是ticket_filter_items有可能拓展
    selectedCorps: function() {
      let res = this.ticket_filter_activeIds.filter(e =>
        AircraftServiceProvider.includes(e)
      );
      return 0 in res ? res : AircraftServiceProvider;
    },

    // 计算列表高度
    listHeight: function() {
      let arr = ["searchAircraftTo", "searchAircraftBack"];
      return arr.includes(this.$route.name)
        ? "calc(100vh - 299px)"
        : "calc(100vh - 260px)";
    }
  },

  activated() {
    let dom = document.getElementsByClassName("v-virtual-scroll")[0];
    if (dom) dom.scrollTop = this.tempTop;
    if (!this.listLoad) this.onRefresh(false);
    this.showChart = true;
  },
  deactivated() {
    let dom = document.getElementsByClassName("v-virtual-scroll")[0];
    if (dom) this.tempTop = dom.scrollTop;
    this.showChart = false;
  },
  mounted() {
    this.showChart = true;
    this.onRefresh(false);
  },

  data() {
    return {
      config_renderType: AircraftAsyncList, // 列表渲染方式 - config.js
      activenav: AircraftDefaultSort, // 底部四个按钮导航 变蓝
      skeletonArr: SkeletonArr, // 骨架数据
      currentlist: init(), // 列表缓存对象 ⭐⭐
      showList: [], // 用来展示的列表
      listLoad: true, // 列表加载状态
      showChart: true, // 柱状图开关 - 解决打包后ng代理柱状图白屏问题
      chartData: null, // 柱状图数据
      show_calendar: false,
      ticket_filter_show: false, // 筛选bar中的详细筛选
      ticket_filter_activeIds: [],
      ticket_filter_activeIndex: 0,
      filterTime: undefined // 子组件筛选日期
    };
  },
  methods: {
    // 加载列表 reset: true先清空再拉。false 不清空原有的 补齐全缺失
    async onRefresh(reset = true) {
      if (this.secondKey && this.$route.name == "searchAircraftTo") return; // 去程不许更行返程数据，keepalive的代价
      // 重置列表
      if (reset) {
        this.showList = [];
        this.currentlist.resetList();
      }
      // 补齐缺失前先对其supplier
      else this.currentlist.itsSupplierNum();
      this.listLoad = true;
      await this.currentlist.update(
        "aircraft",
        this.query,
        this.selectedCorps,
        this.isNormal
      );
    },

    // 日历选择日期
    calendarConfirm(date) {
      this.$emit("updateDate", moment(date).format("YYYY-MM-DD"));
      this.show_calendar = false;
    },

    // 筛选列表排序
    sortList() {
      switch (this.activenav) {
        // 省钱排序
        case "money":
          this.showList.sort((a, b) => {
            return (
              parseInt(a.canBuyMinPrice || 9999) -
              parseInt(b.canBuyMinPrice || 9999)
            );
          });
          break;
        //省时排序
        case "time":
          this.showList.sort((a, b) => {
            return (
              a.runTime.replace(/[^0-9]/gi, "") -
              b.runTime.replace(/[^0-9]/gi, "")
            );
          });
          break;
        // 提早排序
        default:
          this.showList.sort((a, b) => {
            let a_time = parseInt(a.departTime.replace(":", ""));
            let b_time = parseInt(b.departTime.replace(":", ""));
            return parseInt(a_time) - parseInt(b_time);
          });
      }
      let dom = document.getElementsByClassName("v-virtual-scroll")[0];
      if (dom) dom.scrollTop = 0; // 滚回顶部
      this.listLoad = false;
    },

    //筛选filter 确认
    tabbar_filter_confirm() {
      let arr = [];
      this.currentlist.list.forEach(element => {
        let f = true;
        if (this.ticket_filter_activeIds.length > 0) {
          if (this.ticket_filter_activeIds.includes(element.corp)) f = true;
          else f = false;
        }
        if (this.filterTime) {
          if (
            Math.floor((parseInt(this.filterTime.split(":")[0]) - 6) / 3) ==
            Math.floor((parseInt(element.departTime.split(":")[0]) - 6) / 3)
          )
            f = f && true;
          else f = f && false;
        }
        if (f) arr.push(element);
      });
      this.showList = arr;
      this.ticket_filter_show = false;
    },

    // 点击图表筛选列表
    filterDate(data) {
      if (!this.chartData) return;
      this.filterTime = data ? data.time : undefined;
      this.tabbar_filter_confirm();
    },

    //❤️❤️核心：选好票啦~！❤️❤️
    async chooseTicket(ticketInfo) {
      let result = ticketInfo;
      // 普通购票传数组 在订单页比价
      if (this.isNormal) {
        let arr = [];
        // 下方统计同机型舱位，并去除筛选舱位
        let filterCorp = this.ticket_filter_activeIds.filter(e =>
          AircraftServiceProvider.includes(e)
        );
        this.currentlist.list.forEach(ticket => {
          if (
            ticketInfo.flightNo == ticket.flightNo &&
            (filterCorp.length == 0 || filterCorp.includes(ticket.corp))
          )
            arr.push(ticket);
        });
        result = arr;
      }
      this.$emit("chooseTicket", result, {
        corp: ticketInfo.corp
      });
    }
  },

  watch: {
    "currentlist.check": {
      handler: function() {
        // 异步加载
        if (this.config_renderType == "async") {
          if (this.currentlist.supplierNum >= this.selectedCorps.length)
            this.listLoad = false; // 所有请求完成更改”加载状态“
          this.showList = this.currentlist.list; // 有返回就往列表里塞
        }

        // 同步加载
        if (this.config_renderType == "sync") {
          if (
            this.currentlist.supplierNum >= this.selectedCorps.length &&
            this.listLoad
          ) {
            this.showList = this.currentlist.list;
            this.sortList(); // 所有请求完成调用排序
          }
        }

        this.chartData = SortForChart(this.currentlist.list); // 更新柱状图
      }
    },
    "query.date": {
      handler: function() {
        this.onRefresh(false);
      },
      deep: true
    }
  }
};
</script>

<style lang="less" src="@/styles/searchCommon.less" scoped></style>