<template>
  <!-- 基础柱状图，使用在航班列表页的中用 -->
  <div class="canvas-cont">
    <p class="list-text-big">当日分时段最低价</p>
    <canvas id="BaseCol"></canvas>
  </div>
</template>

<script>
const F2 = require("@antv/f2/lib/index");

// 引入所有的交互行为
require("@antv/f2/lib/interaction/");
export default {
  name: "BaseCol",
  props: {
    data: {
      type: Array,
      default: () => [
        {
          time: "06:00",
        },
        {
          sales: 0,
          time: "06:30",
        },
        {
          time: "09:00",
        },
        {
          sales: 0,
          time: "09:30",
        },
        {
          time: "12:00",
        },
        {
          sales: 0,
          time: "12:30",
        },
        {
          time: "15:00",
        },
        {
          sales: 0,
          time: "15:30",
        },
        {
          time: "18:00",
        },
        {
          sales: 0,
          time: "18:30",
        },
        {
          time: "21:00",
        },
        {
          sales: 0,
          time: "21:30",
        },
        {
          time: "24:00",
        },
      ],
    },
  },
  data() {
    return {
      chart: null, // 图
      group: null, // 文字
    };
  },
  mounted() {
    const chart = new F2.Chart({
      id: "BaseCol",
      pixelRatio: window.devicePixelRatio,
    });

    chart.source(this.data, {
      sales: {
        tickCount: 5,
      },
    });
    chart.axis("sales", false);
    chart.axis("time", {
      label: (text, index, total) => {
        return {
          fill: "#333333",
          fillOpacity: index % 2 == 0 ? 1 : 0,
        };
      },
    });
    chart.tooltip(false);
    chart.interval().position("time*sales").size(36).color("",()=>{
      // 获取主题色
      let itemColor = document.querySelector('.active .date-text')
      if(itemColor) itemColor = window.getComputedStyle(itemColor, false)['color']
      return itemColor || '#1890ff'
    });

    this.chart = chart;
    this.chart.render();

    // 绘制柱状图文本
    const offset = -5;
    const canvas = chart.get("canvas");
    const group = canvas.addGroup();
    this.data.forEach(function (obj) {
      const point = chart.getPosition(obj);
      const text = group.addShape("text", {
        attrs: {
          x: point.x,
          y: point.y + offset,
          text: obj.sales < 1 ? "-" : "￥" + obj.sales,
          textAlign: "center",
          textBaseline: "bottom",
          fill: "#333333",
        },
      });
    });
    this.group = group;

    let that = this;
    chart.interaction("interval-select", {
      selectStyle: { fill: "#ff5e00" },
      unSelectStyle: { fillOpacity: 0.8 },
      onEnd(ev) {
        // ev: Object 类型, 该对象包含的重要属性如下：
        // ev.data: Object 类型，被选中图形的原始数据
        // ev.shapeInfo: Object 类型，被选中图形的数据信息
        // ev.selected: 当前 shape 的选中状态
        const { data, shapeInfo, shape, selected } = ev;
        that.$emit("chartClick", data);
      },
    });
  },
  watch: {
    data() {
      // 清空文本
      this.group.clear();
      // 计算柱状图
      const offset = -5;
      this.data.forEach((obj) => {
        const point = this.chart.getPosition(obj);
      });
      // 更新图
      this.chart.changeData(this.data);
      // 根据柱状图计算文本位置
      this.data.forEach((obj) => {
        const point = this.chart.getPosition(obj);
        const text = this.group.addShape("text", {
          attrs: {
            x: point.x,
            y: point.y + offset,
            text: obj.sales < 1 ? "-" : "￥" + obj.sales,
            textAlign: "center",
            textBaseline: "bottom",
            fill: "#333333",
          },
        });
      });
      // 更新图
      this.chart.changeData(this.data);
    },
  },
};
</script>


<style scoped>
.canvas-cont {
  background-color: #fff;
  position: relative;
}
#BaseCol {
  width: 100%;
  height: 8rem;
}
.list-text-big {
  margin-bottom: -10px;
  text-align: center;
}
</style>
