<template>
  <!-- 航班列表 vuetify下的虚拟滚动组件 -->
  <v-virtual-scroll bench="4" ref="virtual" :items="list.concat([null])" :item-height="106" :height="listHeight">
    <template v-slot="{ item }">
      <!-- 最后一条 额外增加个提示 -->
      <div v-if="!item" class="last-item">到底了</div>

      <div v-else class="itembk list-outline" @click="chooseTicket(item)">

        <!-- 上半部分票信息 -->
        <van-row class="item-top" type="flex" justify="space-between" align="center">
          <van-col span="6">
            <van-row class="list-time">{{item.departTime}}</van-row>
            <van-row class="info-text list-text van-ellipsis">{{item.departAirport}}</van-row>
          </van-col>
          <van-col>
            <van-row class="list-text-big" style="transform: translateY(-0.4rem);">{{item.flightNo}}</van-row>
            <van-icon class="iconfont line" class-prefix="icon" name="icon_jipiaodancheng" />
          </van-col>
          <van-col>
            <van-row class="list-time">{{item.arriveTime}}</van-row>
            <van-row class="info-text list-text van-ellipsis">{{item.arriveAirport}}</van-row>
          </van-col>
          <van-col>
            <van-row>
              <span style="color: red;">￥</span>
              <span style="color: red;font-size: 1.2rem">{{item.canBuyMinPrice||'-'}}</span>
              <span class="info-text list-text" style="color:rgb(172, 172, 172)">起</span>
            </van-row>
            <!--服务商标签-->
            <van-row type="flex" justify="center">
              <Logo :corp="item.corp" />
            </van-row>
          </van-col>
        </van-row>

        <!-- 下半边 -->
        <van-row type="flex" align="center" class="item-bottom">
          <van-col>
            <van-image class="errhide" width="10" height="10" :src="item.airlineLogoUrl">
              <template v-slot:error>
                <van-icon class="iconfont blue" class-prefix="icon" name="tuijianjipiao" ref="change" />
              </template>
            </van-image>
            &nbsp;{{item.airlineCompany}}
          </van-col>
          <van-col span="1" style="text-align:center">|</van-col>
          <van-col>{{item.flightModel}}</van-col>
          <van-col span="1" style="text-align:center">|</van-col>
          <van-col>{{item.runTime}}</van-col>
        </van-row>

      </div>
    </template>
  </v-virtual-scroll>
</template>

<script>
import { VVirtualScroll } from "vuetify/lib";
import Logo from "@/components/Logo";
export default {
  name: "AircraftTicketItem",
  props: {
    list: Array,
    listLoad: Boolean
  },
  components: { VVirtualScroll, Logo },
  computed: {
    // 计算列表高度
    listHeight: function() {
      let arr = ["searchAircraftTo", "searchAircraftBack"];
      return arr.includes(this.$route.name)
        ? "calc(100vh - 230px)"
        : "calc(100vh - 200px)";
    }
  },
  methods: {
    // 选票
    chooseTicket(data) {
      if (data.canBuyMinPrice != "") this.$emit("childClick", data);
    }
  }
};
</script>

<style lang="less" src="@/styles/itemCommon.less" scoped></style>
<style lang="less" scoped>
.list-outline {
  max-height: 98px;
  height: 98px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
</style>
<style lang="less">
.errhide .van-image__error-icon {
  font-size: 10px;
}
</style>